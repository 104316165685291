<script lang="ts" setup>
import { onMounted, useTemplateRef } from 'vue';
import PacMan from './PacMan.vue'
import NavBar from './NavBar.vue'
import SiteAnnouncement from './SiteAnnouncement.vue';

const headerRef = useTemplateRef('header');

onMounted(() => {
  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1),
    { threshold: [1] },
  );
  if (headerRef.value) observer.observe(headerRef.value);
});
</script>

<template>
  <PacMan />
  <SiteAnnouncement href="https://www.girls-day.de/.oO/Show/about-source-gmbh/dein-tag-als-programmierer-in">
    <img
      src="./siegel_gd_wir_machen_mit_domain.jpg"
      alt="Girls Day - Wir machen mit Logo"
      style="width: 6rem; padding-right: 0.5rem;"
    >
    Girls Day @ about:source am 03.04.25! ✨
  </SiteAnnouncement>
  <header ref="header">
    <NavBar />
  </header>
</template>

<style lang="scss" scoped>
header {
  position: sticky;
  top: -1px;
  z-index: 99;
  transition: box-shadow 0.1s ease-in-out,
    top 0.2s ease-in-out;

  &.stuck {
    box-shadow: 0 0.1rem 0.5rem #9994;
  }
}
</style>
