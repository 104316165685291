<script lang="ts" setup>
import { useData } from 'vitepress'
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import './utils/print-banner'

const { frontmatter } = useData()
</script>

<template>
  <SiteHeader />
  <main>
    <div v-if="!frontmatter.titleHidden" class="container container-narrow pt-5">
      <h1>{{ frontmatter.title }}</h1>
    </div>

    <Content />
  </main>
  <SiteFooter />
</template>

<style scoped>
main {
  min-height: 100%;
}
</style>
