<script lang="ts" setup>
import { useData } from 'vitepress';
import NavMenu from './NavMenu.vue';

const { site } = useData();
</script>

<template>
  <nav class="navbar navbar-light">
    <div class="container">
      <a
        :href="site.base"
        aria-label="Zurück auf die Startseite"
        class="navbar-brand"
      >
        <img
          class="logo"
          src="../assets/images/as-logo.min.svg?url"
          alt="about:source Logo"
        >
      </a>
      <NavMenu />
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  padding: 0;
  background-color: white;
  height: $nav-bar-height;
}

.container,
.navbar-brand,
.logo {
  height: 100%;
}

.logo {
  padding: 1rem 0;
}
</style>
