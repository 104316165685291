<script lang="ts" setup>
import { useData } from 'vitepress';
import NotFoundImage from 'theme/assets/images/not_found_image.svg';
import GenericContainer from './components/containers/GenericContainer.vue';
import StackLayout from './components/containers/StackLayout.vue';

const { site } = useData();
</script>

<template>
  <GenericContainer>
    <h1>404 Seite nicht gefunden</h1>
    <StackLayout>
      <div class="not-found-image">
        <NotFoundImage />
      </div>
      <p>
        Wohin du gehst, war noch niemand - sogar wir nicht. Schau dich gerne auf
        unserer
        <a :href="site.base">Startseite</a> um oder
        <a href="/kontakt.html">meld dich</a> bei uns!
      </p>
    </StackLayout>
  </GenericContainer>
</template>

<style scoped>
.not-found-image {
  max-width: 600px;
  margin-block: 4rem;
  margin-inline: auto;
}
</style>
